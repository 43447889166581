import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { FileDownloadsCard } from './FileDownloadsCard';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ServiceStackClient, SnackBarOptionsAtom } from '../../Recoil/Atoms';
import { FileDetail, FileDownloadUrl, ListFileDownloadUrls, ListFiles } from '../../ServiceStack/Example.dtos';
import { downloadZip } from 'client-zip';
import streamSaver from 'streamsaver';

export interface IFileDownloadsContainerProps {}

const FileDownloadsController: React.FC<IFileDownloadsContainerProps> = (props) => {
    const styles = useStyles(props);
    const [availableFilesToDownload, setAvailableFilesToDownload] = useState<FileDetail[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<FileDetail[]>([]);
    const fileTableRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptionsAtom);
    const client = useRecoilValue(ServiceStackClient);

    useEffect(() => {
        //Load files available to download

        client
            .get(new ListFiles())
            .then((response) => {
                setAvailableFilesToDownload(response.results);
                setIsLoading(false);
            })
            .catch((error) => {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'There was a problem loading files.',
                });
                setIsLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClickDownloadSelectedFiles = () => {
        client
            .get(new ListFileDownloadUrls({ fileNames: selectedFiles.map((file) => file.name) }))
            .then(async (response) => {
                await onDownloadFiles(response.result.fileDownloadUrls, response.result.archiveName).then(() => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'info' },
                        message: `Downloading ${selectedFiles.length} files.`,
                    });
                    onClearSelections();
                });
            })
            .catch((error) => {
                onErrorDownloadingFiles();
            });
    };

    const onClickDownloadFile = (event: any, data: FileDetail) => {
        client
            .get(new ListFileDownloadUrls({ fileNames: [data.name] }))
            .then(async (response) => {
                await onDownloadFiles(response.result.fileDownloadUrls, response.result.archiveName).then(() => {
                    setSnackbarOptions({
                        snackBarProps: { open: true, autoHideDuration: 6000 },
                        alertProps: { severity: 'info' },
                        message: `Downloading ${data.name}.zip`,
                    });
                    onClearSelections();
                });
            })
            .catch((error) => {
                onErrorDownloadingFiles();
            });
    };

    const onDownloadFiles = async (filesToDownload: FileDownloadUrl[], archiveName: string) => {
        const files = await Promise.all(
            filesToDownload.map(async (file) => {
                return {
                    name: file.fileName,
                    input: await fetch(file.s3PresignedUrl),
                };
            })
        );

        await downloadZip(files).body.pipeTo(streamSaver.createWriteStream(archiveName));
    };

    const onClearSelections = () => {
        if (fileTableRef.current) {
            (fileTableRef as any).current.onAllSelected(false);
        }
        setSelectedFiles([]);
    };

    const onErrorDownloadingFiles = () => {
        setSnackbarOptions({
            snackBarProps: { open: true, autoHideDuration: 6000 },
            alertProps: { severity: 'error' },
            message: selectedFiles.length > 1 ? 'There was a problem downloading your files.' : 'There was a problem downloading your file.',
        });
    };

    return (
        <FileDownloadsCard
            selectedFiles={selectedFiles}
            availableFilesToDownload={availableFilesToDownload}
            fileTableRef={fileTableRef}
            onClickDownloadSelectedFiles={onClickDownloadSelectedFiles}
            onClickDownloadFile={onClickDownloadFile as any}
            setSelectedFiles={setSelectedFiles}
            isLoading={isLoading}
        />
    );
};

const useStyles = makeStyles<IFileDownloadsContainerProps>()((theme, props) => ({}));

export { FileDownloadsController };
