import React, {useState} from 'react';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogContent,
    Grid,
    LinearProgress,
    Snackbar,
    TextField,
    Typography
} from '@mui/material';
import {useRecoilValue} from 'recoil';
import {ServiceStackClient} from '../../Recoil/Atoms';
import {ContactSupport, SubjectType} from "../../ServiceStack/Example.dtos";

export interface ISupportFormProps {
    subjectType : SubjectType;
    show: boolean;
    onClose: () => void;
}

const SupportForm: React.FC<ISupportFormProps> = (props) => {
    const client = useRecoilValue(ServiceStackClient);
    const [sending, setSending] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const sendFeedback = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('firstName'), data.get('lastName'), data.get('email'), data.get('subject'), data.get('message'));
        props.onClose();
        setSending(true);
        client
            .post(new ContactSupport({
                firstName: data.get('firstName').toString(),
                lastName: data.get('lastName').toString(),
                email: data.get('email').toString(),
                subjectType: props.subjectType,
                feedback: props.subjectType === SubjectType.Support ? data.get('message').toString() : "",
                subject: props.subjectType === SubjectType.Support ? data.get('subject').toString() : "",
            }))
            .then(() => {
                setSending(false);
                setShowSuccessSnackbar(true);
                props.onClose();
            })
            .catch((error) => {
                setSending(false);
                setShowErrorSnackbar(true);
                props.onClose();
            });
    };

    return (
        <>
            <Dialog
                open={props.show}
                onClose={() => props.onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                onBackdropClick={props.onClose}
            >
                <Collapse in={sending}>
                    <LinearProgress variant='indeterminate' />
                </Collapse>
                <DialogContent>
                    <Grid>
                        {props.subjectType === SubjectType.Support ?
                            <Typography gutterBottom variant='h5'>
                                Contact Us
                            </Typography>
                            :
                            <Typography gutterBottom variant='h5'>
                                Forgot Password?
                            </Typography>
                        }
                        <Typography variant='body2' color='textSecondary' component='p' gutterBottom>
                            Fill out the form and our team will get back to you within 24 hours.
                        </Typography>
                        <form onSubmit={sendFeedback}>
                            <Grid container spacing={1}>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        placeholder='Enter first name'
                                        label='First Name'
                                        name={'firstName'}
                                        id={'firstName'}
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid xs={12} sm={6} item>
                                    <TextField
                                        name={'lastName'}
                                        id={'lastName'}
                                        placeholder='Enter last name'
                                        label='Last Name'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name={'email'}
                                        id={'email'}
                                        type='email'
                                        placeholder='Enter email'
                                        label='Email'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                { props.subjectType === SubjectType.Support &&
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'subject'}
                                            id={'subject'}
                                            placeholder='Enter the subject'
                                            label='Subject'
                                            variant='outlined'
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                }
                                {props.subjectType === SubjectType.Support &&
                                    <Grid item xs={12}>
                                        <TextField
                                            name={'message'}
                                            id={'message'}
                                            label='Message'
                                            multiline
                                            rows={4}
                                            placeholder='Type your message here'
                                            variant='outlined'
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Button type='submit' variant='contained' color='primary' fullWidth>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </DialogContent>
            </Dialog>
            {/*ToDo add common snackbar to AppLayout and control through recoil*/}
            <Snackbar
                open={showErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={() => setShowErrorSnackbar(false)}
            >
                <Alert severity={'error'} onClose={() => setShowErrorSnackbar(false)}>
                    <Typography>There was a problem contacting support!</Typography>
                </Alert>
            </Snackbar>
            <Snackbar
                open={showSuccessSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={() => setShowSuccessSnackbar(false)}
            >
                <Alert severity={'success'} onClose={() => setShowSuccessSnackbar(false)}>
                    <Typography>Your request has been sent!</Typography>
                </Alert>
            </Snackbar>
        </>
    );
};

export { SupportForm };
