import * as ReactDOMClient from 'react-dom/client';
import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {CacheProvider} from "@emotion/react";
import {RecoilRoot} from "recoil";
import { ThemeProvider} from "@mui/material";
import createCache from '@emotion/cache';
import {vegaCostNavigatorTheme} from "./Styling/Theme";
import {VegaCostNavigatorRoutes} from "./Routes/VegaCostNavigatorRoutes";

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container);

export const muiCache = createCache({
    key: 'mui', // all material ui classes start with 'css' instead of 'mui' even with this here
    prepend: true,
});

root.render(
    <React.StrictMode>
        <CacheProvider value={muiCache}>
            <BrowserRouter>
                <RecoilRoot>
                    <ThemeProvider theme={vegaCostNavigatorTheme} >
                       <VegaCostNavigatorRoutes />
                    </ThemeProvider>
                </RecoilRoot>
            </BrowserRouter>
        </CacheProvider>
    </React.StrictMode>
);

