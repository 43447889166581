import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '../../Styling/MakeStyles';

export interface IErrorScreenProps extends FallbackProps {}

const ErrorScreen: React.FC<IErrorScreenProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item>
                <Typography variant={'h6'} color={'red'}>
                    We're sorry something went wrong!
                </Typography>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IErrorScreenProps>()((theme, props) => ({}));

export { ErrorScreen };
