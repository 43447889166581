import React from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { Button, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { FileDownloadsTable } from './FileDownloadsTable';
import { cx } from 'tss-react/@emotion/css';
import { FileDetail } from '../../ServiceStack/Example.dtos';

export interface IFileDownloadsProps {
    fileTableRef: React.MutableRefObject<undefined>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<FileDetail[]>>;
    selectedFiles: FileDetail[];
    availableFilesToDownload: FileDetail[];
    onClickDownloadSelectedFiles(): void;
    onClickDownloadFile: (event: any, data: FileDetail | FileDetail[]) => void;
    isLoading: boolean;
}

const FileDownloadsCard: React.FC<IFileDownloadsProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Card elevation={0}>
            <CardContent className={cx(styles.classes.cardContent)}>
                <Grid container direction={'column'}>
                    <Grid item xs={12} container direction={'row'} justifyContent={'space-between'}>
                        <Grid xs={6} item>
                            <Typography variant={'h5'}>Document Download</Typography>
                        </Grid>
                        <Grid xs={6} item container justifyContent={'flex-end'}>
                            {props.selectedFiles.length > 1 ? (
                                <Button variant={'contained'} onClick={props.onClickDownloadSelectedFiles}>
                                    Download selected files
                                </Button>
                            ) : (
                                <div className={cx(styles.classes.documentDownloadPlaceholder)}></div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <FileDownloadsTable
                    availableFilesToDownload={props.availableFilesToDownload}
                    fileTableRef={props.fileTableRef}
                    setSelectedFiles={props.setSelectedFiles}
                    selectedFiles={props.selectedFiles}
                    onClickDownloadFile={props.onClickDownloadFile}
                    isLoading={props.isLoading}
                />
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles<IFileDownloadsProps>()((theme, props) => ({
    cardContent: {
        marginBottom: '-1.5rem',
    },
    documentDownloadPlaceholder: {
        height: '2.25rem',
    },
}));

export { FileDownloadsCard };
