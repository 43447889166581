import React, { useState } from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { Grid, Tab, Tabs } from '@mui/material';
import { QuicksightReport } from './QuicksightReport';
import { QuicksightDashboard } from '../../ServiceStack/Example.dtos';

export interface IQuicksightReportTabsProps {
    reports: QuicksightDashboard[];
    dashboardUrl: string;
}

const QuicksightReportTabs: React.FC<IQuicksightReportTabsProps> = (props) => {
    const styles = useStyles(props);
    const [currentTab, setCurrentTab] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <Grid container spacing={2} direction={'column'} className={styles.classes.tabsContainer}>
            <Grid item xs={12}>
                <Tabs
                    variant={'scrollable'}
                    id={'reportTabs'}
                    value={currentTab}
                    onChange={handleTabChange}
                    aria-label='Select the report you want to work with'
                >
                    {props.reports &&
                        props.reports.map((dashboard, index) => (
                            <Tab id={`${dashboard.dashboardName}-${index}`} key={index} label={dashboard.dashboardName} value={index} />
                        ))}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                <QuicksightReport dashboardUrl={props.dashboardUrl} dashboardId={props.reports[currentTab].dashboardId} />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles<IQuicksightReportTabsProps>()((theme, props) => ({
    tabsContainer: {
        marginTop: '-2.5rem',
    },
}));

export { QuicksightReportTabs };
