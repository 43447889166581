import React from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import MaterialTable, { MTableToolbar } from 'material-table';
import { tableIcons } from '../Common/TableIcons';
import Paper from '@mui/material/Paper';
import { cx } from 'tss-react/@emotion/css';
import { Typography, useTheme } from '@mui/material';
import { FileDownloadOutlined, FilePresent } from '@mui/icons-material';
import { FileDetail } from '../../ServiceStack/Example.dtos';
import moment from 'moment';

export interface IFileDownloadsTableProps {
    isLoading: boolean;
    fileTableRef: React.MutableRefObject<undefined>;
    setSelectedFiles: React.Dispatch<React.SetStateAction<FileDetail[]>>;
    selectedFiles: FileDetail[];
    availableFilesToDownload: FileDetail[];
    onClickDownloadFile: (event: any, data: FileDetail | FileDetail[]) => void;
}

const FileDownloadsTable: React.FC<IFileDownloadsTableProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();

    return (
        <MaterialTable
            icons={tableIcons}
            tableRef={props.fileTableRef}
            components={{
                Toolbar: (props) => <MTableToolbar {...props} className={cx(styles.classes.toolBar)} />,
                Container: (props) => <Paper {...props} elevation={0} />,
            }}
            columns={[
                {
                    title: 'File Name',
                    field: 'name',
                    render: (rowData) => {
                        return (
                            <div style={{ display: 'inline-block' }}>
                                <FilePresent className={cx(styles.classes.fileIcon)} />
                                {rowData.name}
                            </div>
                        );
                    },
                },
                {
                    title: 'Date Added',
                    field: 'lastModified',
                    render: (rowData) => {
                        return moment(rowData.lastModified).format('MM/DD/YYYY h:mm A');
                    },
                },
                {
                    title: 'File Size',
                    field: 'contentLengthInKilobytes',
                    render: (rowData) => {
                        return `${formatBytes(Number(rowData.contentLengthInBytes))}`;
                    },
                },
            ]}
            data={props.availableFilesToDownload}
            localization={{
                toolbar: {
                    searchPlaceholder: 'Search...',
                },
                header: {
                    actions: '',
                },
            }}
            actions={[
                {
                    icon: () => <FileDownloadOutlined />,
                    tooltip: props.selectedFiles.length > 1 ? '' : 'Download File',
                    onClick: props.onClickDownloadFile,
                    position: 'row',
                    disabled: props.selectedFiles.length > 1,
                },
            ]}
            options={{
                actionsColumnIndex: -1,
                showTitle: false,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                searchFieldAlignment: 'left',
                searchFieldVariant: 'outlined',
                searchFieldStyle: {
                    marginLeft: '-1.5rem',
                    marginRight: '-1.5rem',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                },
                headerStyle: {
                    backgroundColor: `${theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]}`,
                    color: theme.palette.grey[500],
                },
                selection: true,
                showTextRowsSelected: false,
            }}
            onSelectionChange={props.setSelectedFiles}
            isLoading={props.isLoading}
        />
    );
};

//https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

function formatBytes(x) {
    let l = 0,
        n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
        n = n / 1024;
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}

const useStyles = makeStyles<IFileDownloadsTableProps>()((theme, props) => ({
    toolBar: {
        '& .MuiFormControl-root': {
            minWidth: '100%',
        },
    },
    fileIcon: {
        height: '0.875rem',
        marginBottom: '-.05rem',
        marginLeft: '-.5rem',
    },
}));

export { FileDownloadsTable };
