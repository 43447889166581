/* Options:
Date: 2022-09-28 10:52:07
Version: 6.21
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://local.vegacloud.io:5001

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum SubjectType
{
    Support = 'Support',
    Password = 'Password',
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class CostNavigatorSettings
{
    public useInMemmoryDatabase: boolean;
    public databaseConnectionString: string;
    public useInMemoryLogging: boolean;
    public logDatabaseConnectionString: string;
    public defaultDashboardId: string;
    public customer: string;
    public awsAccountId: string;
    public iamReportUserRole: string;
    public quicksightUserRegion: string;
    public quicksightDashboardRegion: string;
    public rowLevelPermissionsFilter: string;
    public dashboardNameFilter: string;
    public debuggingEnabled: boolean;
    public fileDownloadsBucketName: string;
    public awsRegion: string;

    public constructor(init?: Partial<CostNavigatorSettings>) { (Object as any).assign(this, init); }
}

export class BaseQuicksightCache
{
    public cacheId: number;

    public constructor(init?: Partial<BaseQuicksightCache>) { (Object as any).assign(this, init); }
}

export class QuicksightUser extends BaseQuicksightCache
{
    public active: boolean;
    public arn: string;
    public customPermissionsName: string;
    public email: string;
    public externalLoginFederationProviderType: string;
    public externalLoginFederationProviderUrl: string;
    public externalLoginId: string;
    public identityType: string;
    public principalId: string;
    public role: string;
    public userName: string;

    public constructor(init?: Partial<QuicksightUser>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightGroup extends BaseQuicksightCache
{
    public arn: string;
    public description: string;
    public groupName: string;
    public principalId: string;

    public constructor(init?: Partial<QuicksightGroup>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightDashboardSummary extends BaseQuicksightCache
{
    public arn: string;
    public createdTime: string;
    public dashboardId: string;
    public lastPublishedTime: string;
    public lastUpdatedTime: string;
    public name: string;
    public publishedVersionNumber: number;

    public constructor(init?: Partial<QuicksightDashboardSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightDataSetSummary extends BaseQuicksightCache
{
    public arn: string;
    public columnLevelPermissionRulesApplied: boolean;
    public createdTime: string;
    public dataSetId: string;
    public importMode: string;
    public lastUpdatedTime: string;
    public name: string;
    public rowLevelPermissionDataSet: string;
    public rowLevelPermissionTagConfigurationApplied: boolean;

    public constructor(init?: Partial<QuicksightDataSetSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightTemplateSummary extends BaseQuicksightCache
{
    public arn: string;
    public createdTime: string;
    public lastUpdatedTime: string;
    public latestVersionNumber: number;
    public name: string;
    public templateId: string;

    public constructor(init?: Partial<QuicksightTemplateSummary>) { super(init); (Object as any).assign(this, init); }
}

export class QuicksightPermissions
{
    public actions: string[];
    public principal: string;

    public constructor(init?: Partial<QuicksightPermissions>) { (Object as any).assign(this, init); }
}

export class QueryDashboardUsersDetail
{
    public dashboardArn: string;
    public dashboardId: string;
    public dashboardName: string;
    public principal: string;
    public userName: string;
    public email: string;

    public constructor(init?: Partial<QueryDashboardUsersDetail>) { (Object as any).assign(this, init); }
}

export class QuicksightEmbeddedUrl
{
    public url: string;

    public constructor(init?: Partial<QuicksightEmbeddedUrl>) { (Object as any).assign(this, init); }
}

export class QuicksightDashboard
{
    public dashboardName: string;
    public dashboardId: string;

    public constructor(init?: Partial<QuicksightDashboard>) { (Object as any).assign(this, init); }
}

export class FileDetail
{
    public name: string;
    public contentLengthInBytes: number;
    public lastModified: string;

    public constructor(init?: Partial<FileDetail>) { (Object as any).assign(this, init); }
}

export class FileDownloadUrl
{
    public fileName: string;
    public s3PresignedUrl: string;

    public constructor(init?: Partial<FileDownloadUrl>) { (Object as any).assign(this, init); }
}

export class ListFileDownloadUrlsResult
{
    public fileDownloadUrls: FileDownloadUrl[];
    public archiveName: string;

    public constructor(init?: Partial<ListFileDownloadUrlsResult>) { (Object as any).assign(this, init); }
}

export class GetVersionResponse
{
    public results: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetVersionResponse>) { (Object as any).assign(this, init); }
}

export class StatusCheckResponse
{
    public results: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StatusCheckResponse>) { (Object as any).assign(this, init); }
}

export class StatusSettingsResponse
{
    public results: CostNavigatorSettings;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<StatusSettingsResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightUsersResponse
{
    public results: QuicksightUser[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightUsersResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightGroupsResponse
{
    public results: QuicksightGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightGroupsResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightDashboardResponse
{
    public results: QuicksightDashboardSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightDashboardResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightDataSetResponse
{
    public results: QuicksightDataSetSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightDataSetResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightUserGroupsResponse
{
    public results: QuicksightGroup[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightUserGroupsResponse>) { (Object as any).assign(this, init); }
}

export class ListQuicksightTemplatesResponse
{
    public results: QuicksightTemplateSummary[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListQuicksightTemplatesResponse>) { (Object as any).assign(this, init); }
}

export class GetQuicksightDashboardPermissionsResponse
{
    public results: QuicksightPermissions[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuicksightDashboardPermissionsResponse>) { (Object as any).assign(this, init); }
}

export class QueryDashboardUsersResponse
{
    public results: QueryDashboardUsersDetail[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueryDashboardUsersResponse>) { (Object as any).assign(this, init); }
}

export class GetQuicksightDashboardEmbeddedUrlResponse
{
    public results: QuicksightEmbeddedUrl;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuicksightDashboardEmbeddedUrlResponse>) { (Object as any).assign(this, init); }
}

export class GetQuicksightDashboardsResponse
{
    public results: QuicksightDashboard[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetQuicksightDashboardsResponse>) { (Object as any).assign(this, init); }
}

export class DeleteQuicksightUserResponse
{
    public status: boolean;

    public constructor(init?: Partial<DeleteQuicksightUserResponse>) { (Object as any).assign(this, init); }
}

export class ListFilesResponse
{
    public results: FileDetail[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListFilesResponse>) { (Object as any).assign(this, init); }
}

export class ListFileDownloadUrlsResponse
{
    public result: ListFileDownloadUrlsResult;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ListFileDownloadUrlsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConvertSessionToTokenResponse
{
    // @DataMember(Order=1)
    public meta: { [index: string]: string; };

    // @DataMember(Order=2)
    public accessToken: string;

    // @DataMember(Order=3)
    public refreshToken: string;

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConvertSessionToTokenResponse>) { (Object as any).assign(this, init); }
}

// @Route("/version")
export class GetVersion implements IReturn<GetVersionResponse>
{

    public constructor(init?: Partial<GetVersion>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetVersion'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetVersionResponse(); }
}

// @Route("/status/check/database")
export class StatusCheckDatabase implements IReturn<StatusCheckResponse>
{

    public constructor(init?: Partial<StatusCheckDatabase>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusCheckDatabase'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusCheckResponse(); }
}

// @Route("/status/settings")
export class StatusSettings implements IReturn<StatusSettingsResponse>
{

    public constructor(init?: Partial<StatusSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'StatusSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new StatusSettingsResponse(); }
}

/**
* ContactSupport
*/
// @Route("/support", "POST")
// @Api(Description="ContactSupport")
export class ContactSupport implements IReturnVoid, IPost
{
    /**
    * User's First Name
    */
    // @ApiMember(DataType="string", Description="User's First Name", IsRequired=true, Name="FirstName")
    public firstName: string;

    /**
    * User's Last Name
    */
    // @ApiMember(DataType="string", Description="User's Last Name", IsRequired=true, Name="LastName")
    public lastName: string;

    /**
    * User's Email Address
    */
    // @ApiMember(DataType="string", Description="User's Email Address", IsRequired=true, Name="Email")
    public email: string;

    /**
    * Type of Subject
    */
    // @ApiMember(DataType="enum", Description="Type of Subject", IsRequired=true, Name="SubjectType")
    public subjectType: SubjectType;

    /**
    * User's Feedback
    */
    // @ApiMember(DataType="string", Description="User's Feedback", Name="Feedback")
    public feedback: string;

    /**
    * Subject
    */
    // @ApiMember(DataType="string", Description="Subject", Name="Subject")
    public subject: string;

    public constructor(init?: Partial<ContactSupport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ContactSupport'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/quicksight/users")
export class ListQuicksightUsers implements IReturn<ListQuicksightUsersResponse>
{

    public constructor(init?: Partial<ListQuicksightUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightUsersResponse(); }
}

// @Route("/quicksight/groups")
export class ListQuicksightGroups implements IReturn<ListQuicksightGroupsResponse>
{

    public constructor(init?: Partial<ListQuicksightGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightGroupsResponse(); }
}

// @Route("/quicksight/dashboards")
export class ListQuicksightDashboards implements IReturn<ListQuicksightDashboardResponse>
{

    public constructor(init?: Partial<ListQuicksightDashboards>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightDashboards'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightDashboardResponse(); }
}

// @Route("/quicksight/datasets")
export class ListQuicksightDataSet implements IReturn<ListQuicksightDataSetResponse>
{

    public constructor(init?: Partial<ListQuicksightDataSet>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightDataSet'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightDataSetResponse(); }
}

// @Route("/quicksight/users/{name}/groups")
export class ListQuicksightUserGroups implements IReturn<ListQuicksightUserGroupsResponse>
{
    public name: string;

    public constructor(init?: Partial<ListQuicksightUserGroups>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightUserGroups'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightUserGroupsResponse(); }
}

// @Route("/quicksight/templates")
export class ListQuicksightTemplates implements IReturn<ListQuicksightTemplatesResponse>
{

    public constructor(init?: Partial<ListQuicksightTemplates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListQuicksightTemplates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListQuicksightTemplatesResponse(); }
}

// @Route("/quicksight/dashboard/{name}/permissions")
export class GetQuicksightDashboardPermissions implements IReturn<GetQuicksightDashboardPermissionsResponse>
{
    public name: string;

    public constructor(init?: Partial<GetQuicksightDashboardPermissions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQuicksightDashboardPermissions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetQuicksightDashboardPermissionsResponse(); }
}

// @Route("/quicksight/query/dashboardusers")
export class QueryDashboardUsers implements IReturn<QueryDashboardUsersResponse>
{

    public constructor(init?: Partial<QueryDashboardUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueryDashboardUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new QueryDashboardUsersResponse(); }
}

// @Route("/quicksight/dashboard/url/{name}/")
export class GetQuicksightDashboardEmbeddedUrl implements IReturn<GetQuicksightDashboardEmbeddedUrlResponse>
{
    public dashboardId: string;

    public constructor(init?: Partial<GetQuicksightDashboardEmbeddedUrl>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQuicksightDashboardEmbeddedUrl'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetQuicksightDashboardEmbeddedUrlResponse(); }
}

// @Route("/quicksight/dashboards")
export class GetQuicksightDashboards implements IReturn<GetQuicksightDashboardsResponse>
{
    public name: string;

    public constructor(init?: Partial<GetQuicksightDashboards>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetQuicksightDashboards'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetQuicksightDashboardsResponse(); }
}

// @Route("/quicksight/deleteUser")
export class DeleteQuicksightUser implements IReturn<DeleteQuicksightUserResponse>
{
    public userName: string;

    public constructor(init?: Partial<DeleteQuicksightUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteQuicksightUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DeleteQuicksightUserResponse(); }
}

/**
* ListFiles
*/
// @Route("/file/list", "GET")
// @Api(Description="ListFiles")
export class ListFiles implements IReturn<ListFilesResponse>
{

    public constructor(init?: Partial<ListFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListFilesResponse(); }
}

/**
* ListFileDownloadUrls
*/
// @Route("/file/listFileUrls", "GET")
// @Api(Description="ListFileDownloadUrls")
export class ListFileDownloadUrls implements IReturn<ListFileDownloadUrlsResponse>
{
    // @ApiMember(DataType="string", IsRequired=true, Name="FileNames")
    public fileNames: string[];

    public constructor(init?: Partial<ListFileDownloadUrls>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ListFileDownloadUrls'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ListFileDownloadUrlsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/session-to-token")
// @DataContract
export class ConvertSessionToToken implements IReturn<ConvertSessionToTokenResponse>, IPost
{
    // @DataMember(Order=1)
    public preserveSession: boolean;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ConvertSessionToToken>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConvertSessionToToken'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConvertSessionToTokenResponse(); }
}

