import { atom, atomFamily, selectorFamily } from 'recoil';
import {
    Authenticate,
    AuthenticateResponse,
    GetQuicksightDashboardEmbeddedUrl,
    GetQuicksightDashboardEmbeddedUrlResponse,
    GetQuicksightDashboardPermissions,
    GetQuicksightDashboards,
    QuicksightDashboard,
    QuicksightEmbeddedUrl,
    QuicksightPermissions,
} from '../ServiceStack/Example.dtos';
import { JsonServiceClient } from '@servicestack/client';
import { SnackBarOptions } from '../Types/Types';

//This may need to go
//const packageConfig = require('../../package.json');
//let baseUrl = packageConfig['proxy'];

let baseUrl = '/';

const getClient = () => {
    const client = new JsonServiceClient(baseUrl);
    client.onAuthenticationRequired = async () => {
        const authClient = new JsonServiceClient(baseUrl);
        const response = await authClient.get(new Authenticate({ rememberMe: true }));
        client.useTokenCookie = true;
    };

    return client;
};

export const Authentication = atom<AuthenticateResponse>({
    key: 'Authentication',
    default: undefined,
});

export const ServiceStackClient = atom({
    key: 'ServiceStackClient',
    default: getClient(),
    dangerouslyAllowMutability: true,
});

export const GetDashboardPermissions = selectorFamily<QuicksightPermissions[], string>({
    dangerouslyAllowMutability: true, //done to allow material-table to work
    key: 'GetDashboardPermissions',
    get:
        (
            name //DashboardName
        ) =>
        async ({ get }) => {
            const client = await getClient();
            const request = new GetQuicksightDashboardPermissions({ name: name });
            const response = await client.get(request);
            return response.results;
        },
});

export const SnackBarOptionsAtom = atom<SnackBarOptions>({
    key: 'SnackBarOptionsAtom',
    default: {
        snackBarProps: {},
        alertProps: {},
        message: '',
    },
});

export const CurrentReportsRequestId = atomFamily({
    key: 'CurrentReportsRequestId',
    default: '',
});

export const CurrentReports = selectorFamily<QuicksightDashboard[], string>({
    key: 'CurrentReports',
    get:
        (id) =>
        async ({ get }) => {
            get(CurrentReportsRequestId(id)); // Add request ID as a dependency

            const client = await getClient();
            const request = new GetQuicksightDashboards();

            const response = await client.get(request);
            return response.results;
        },
});
