import { Components, createTheme } from '@mui/material';
import { VegaPalette } from './VegaPalette';

const ComponentOverrides = {
    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
            },
        },
    },
} as Components;

export const vegaCostNavigatorTheme = createTheme({ palette: VegaPalette, components: ComponentOverrides });
