import React, { useEffect, useState } from 'react';
import { embedDashboard, EmbeddingOptions } from 'amazon-quicksight-embedding-sdk';
import { cx } from 'tss-react/@emotion/css';
import { Card, Grid } from '@mui/material';
import { makeStyles } from '../../Styling/MakeStyles';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SnackBarOptionsAtom } from '../../Recoil/Atoms';

export interface IQuickSightReportProps {
    dashboardUrl: string;
    dashboardId: string;
}

export const QuicksightReport: React.FC<IQuickSightReportProps> = (props) => {
    const [snackBarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptionsAtom);
    const [quicksightDashboard, setQuicksightDashboard] = useState(null);
    const [isDashboardLoaded, setIsDashboardLoaded] = useState(false);
    const styles = useStyles();

    useEffect(() => {
        if (props.dashboardUrl !== undefined) {
            if (props.dashboardUrl === 'Error') {
                setSnackbarOptions({
                    snackBarProps: { open: true, autoHideDuration: 6000 },
                    alertProps: { severity: 'error' },
                    message: 'There was a problem loading visuals',
                });
            } else {
                loadQuicksightDashboard(props.dashboardUrl);
            }
        }
    }, [props.dashboardUrl]);

    useEffect(() => {
        if (props.dashboardId !== null && isDashboardLoaded) {
            loadQuicksightDashboard(props.dashboardUrl);
        }
    }, [props.dashboardId]);

    const onError = (payload) => {
        setSnackbarOptions({
            snackBarProps: { open: true, autoHideDuration: 6000 },
            alertProps: { severity: 'error' },
            message: 'There was a problem loading visuals',
        });
    };

    const onDashboardLoad = (payload) => {
        setIsDashboardLoaded(true);
    };

    const loadQuicksightDashboard = (dUrl) => {
        if (quicksightDashboard === null) {
            var options: EmbeddingOptions = {
                url: dUrl,
                scrolling: 'no',
                height: '100%',
                width: '100%',
                locale: 'en-US',
                footerPaddingEnabled: true,
                container: document.getElementById('qsContainer'),
                printEnabled: true,
            };
            const qsDash = embedDashboard({ ...options });
            qsDash.on('error', onError);
            qsDash.on('load', onDashboardLoad);
            setQuicksightDashboard(qsDash);
        } else {
            quicksightDashboard.navigateToDashboard({ dashboardId: props.dashboardId });
        }
    };

    return <Card elevation={0} className={`${cx(styles.classes.reportContainer)}`} id='qsContainer' />;
};

const useStyles = makeStyles()(() => ({
    reportContainer: {
        height: '100vh',
        quicksightEmbeddingIframe: {
            quicksightEmbeddingIframe_clickableQuicksightAttribution: {
                display: 'none',
            },
        },
    },
}));
