import {Alert, Box, Button, Container, CssBaseline, Grid, Link, Snackbar, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {makeStyles} from '../../Styling/MakeStyles';
import {useRecoilState, useRecoilValue} from 'recoil';
import {Authentication, ServiceStackClient} from '../../Recoil/Atoms';
import {Authenticate, SubjectType} from '../../ServiceStack/Example.dtos';
import {cx} from 'tss-react/@emotion/css';
import {ReactComponent as VegaLogo} from '../../Images/vega-logo.svg';
import {SupportForm} from '../SupportForm/SupportForm';
import {LocationState} from '../../Types/Types';
import {RouteUrls} from '../../Routes/RouteUrls';
import {useLocation, useNavigate} from 'react-router-dom';

export interface ISignInProps {}

const SignIn: React.FC<ISignInProps> = (props) => {
    const client = useRecoilValue(ServiceStackClient);
    const [authenticationResponse, setAuthenticationResponse] = useRecoilState(Authentication);
    const [showSupportForm, setShowSupportForm] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        //handle directing to previous page before needing new authorization
        const { from } = location.state !== null && (location.state as LocationState);

        if (authenticationResponse && location.state && from) {
            navigate(from);
        } else if (authenticationResponse) {
            navigate(RouteUrls.Landing);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticationResponse]);

    const styles = useStyles(props);

    const onClickLogin = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        client
            .post(
                new Authenticate({
                    provider: 'credentials',
                    userName: data.get('email').toString(),
                    password: data.get('password').toString(),
                    rememberMe: true,
                })
            )
            .then((response) => {
                client.useTokenCookie = true;
                setAuthenticationResponse(response);
            })
            .catch((error) => setShowErrorSnackbar(true));
    };

    return (
        <Container component='main' maxWidth='xs'>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={showErrorSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowErrorSnackbar(false)}
            >
                <Alert onClose={() => setShowErrorSnackbar(false)} severity='error' sx={{ width: '100%' }}>
                    That combination of username and password doesn't match our records.
                </Alert>
            </Snackbar>
            <SupportForm subjectType={SubjectType.Support} show={showSupportForm} onClose={() => setShowSupportForm(false)} />
            <SupportForm subjectType={SubjectType.Password} show={showPasswordForm} onClose={() => setShowPasswordForm(false)} />
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <VegaLogo className={cx(styles.classes.VegaLogo)} />
                <Typography component='h1' variant='h5'>
                    Sign in
                </Typography>
                <Box component='form' onSubmit={onClickLogin} noValidate sx={{ mt: 1 }}>
                    <TextField margin='normal' required fullWidth id='email' label='Email Address' name='email' autoComplete='email' autoFocus />
                    <TextField
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                    />
                    <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs onClick={() => setShowPasswordForm(true)}>
                            <Link variant='body2'>{'Forgot Password?'}</Link>
                        </Grid>
                        <Grid item onClick={() => setShowSupportForm(true)}>
                            <Link variant='body2'>{'Contact Support?'}</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

const useStyles = makeStyles<ISignInProps>()((theme, props) => ({
    FooterContainer: {
        bottom: '0%',
        left: 0,
        position: 'absolute',
        width: '100%',
    },
    VegaLogo: {
        height: '6rem',
    },
}));

export { SignIn };
