import React from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { Skeleton, Stack, Typography } from '@mui/material';

export interface IQuicksightReportLoadingSkeletonProps {}

const QuicksightReportLoadingSkeleton: React.FC<IQuicksightReportLoadingSkeletonProps> = (props) => {
    const styles = useStyles(props);

    return (
        <Stack spacing={1} className={styles.classes.container}>
            <Typography component='div' variant={'h2'}>
                <Skeleton />
            </Typography>
            <Skeleton variant='rectangular' width={'100%'} height={'100vh'} />
        </Stack>
    );
};

const useStyles = makeStyles<IQuicksightReportLoadingSkeletonProps>()((theme, props) => ({
    container: {
        marginTop: '-2.5rem',
    },
}));

export { QuicksightReportLoadingSkeleton };
