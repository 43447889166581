import { Navigate, useLocation } from 'react-router-dom';
import React, {PropsWithChildren} from 'react';
import { useRecoilValue } from 'recoil';
import { RouteUrls } from './RouteUrls';
import { Authentication } from '../Recoil/Atoms';

export interface IRoutePrivateProps {
    requiredRoles?: string[];
}

export const RoutePrivate: React.FC<PropsWithChildren<IRoutePrivateProps>> = (props) => {
    const authenticationResponse = useRecoilValue(Authentication);
    const location = useLocation();

    return authenticationResponse ? (
        <>{props.children}</>
    ) : (
        <Navigate
            to={{
                pathname: RouteUrls.Login,
            }}
            state={{ from: location.pathname }}
        />
    );
};