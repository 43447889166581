import React from 'react';
import { Alert, Grid, Snackbar as MuiSnackbar, Stack } from '@mui/material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { SnackBarOptionsAtom } from '../../Recoil/Atoms';
import { cx } from 'tss-react/@emotion/css';
import { makeStyles } from '../../Styling/MakeStyles';

export interface IAdminSnackbarProps {}

const CustomSnackbar: React.FC<IAdminSnackbarProps> = (props) => {
    const styles = useStyles(props);
    const [snackbarOptions, setSnackbarOptions] = useRecoilState(SnackBarOptionsAtom);

    const onCloseSnackbar = () => {
        setSnackbarOptions({ snackBarProps: {}, alertProps: {}, message: '' });
    };

    return (
        <MuiSnackbar
            className={cx(styles.classes.Snackbar)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={onCloseSnackbar}
            {...snackbarOptions.snackBarProps}
        >
            <Alert sx={{ textAlign: 'center' }} className={cx(styles.classes.Alert)} onClose={onCloseSnackbar} {...snackbarOptions.alertProps}>
                <Stack>{snackbarOptions.message}</Stack>
            </Alert>
        </MuiSnackbar>
    );
};

const useStyles = makeStyles<IAdminSnackbarProps>()((theme, props) => ({
    Snackbar: {
        width: '50%',
    },
    Alert: {
        width: '80%',
    },
}));

export { CustomSnackbar };
