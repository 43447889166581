import React, { useEffect, useState } from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Authentication, CurrentReports, CurrentReportsRequestId, ServiceStackClient } from '../../Recoil/Atoms';
import { QuicksightReportTabs } from './QuicksightReportTabs';
import { GetQuicksightDashboardEmbeddedUrl } from '../../ServiceStack/Example.dtos';
import { QuicksightReportLoadingSkeleton } from './QuicksightReportLoadingSkeleton';

export interface IQuicksightReportsControllerProps {}

const QuicksightReportsController: React.FC<IQuicksightReportsControllerProps> = (props) => {
    const styles = useStyles(props);
    const authorized = useRecoilValue(Authentication);
    const currentRequestId = authorized ? authorized.userName : '';
    const reports = useRecoilValue(CurrentReports(currentRequestId));
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const client = useRecoilValue(ServiceStackClient);

    useEffect(() => {
        if (reports.length > 0) {
            client
                .get(new GetQuicksightDashboardEmbeddedUrl({ dashboardId: reports[0].dashboardId }))
                .then((response) => {
                    setDashboardUrl(response.results.url);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setDashboardUrl('Error');
                    setIsLoading(false);
                });
        }
    }, [reports]);

    return isLoading ? <QuicksightReportLoadingSkeleton /> : <QuicksightReportTabs reports={reports} dashboardUrl={dashboardUrl} />;
};

const useStyles = makeStyles<IQuicksightReportsControllerProps>()((theme, props) => ({}));

export { QuicksightReportsController };
