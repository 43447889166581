import { Box, CssBaseline, Grid, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { makeStyles } from '../../Styling/MakeStyles';
import { SignIn } from './SignIn';
import SignInImage from '../../Images/sign-in-image.svg';
import { cx } from 'tss-react/@emotion/css';
import { Footer } from '../Footer/Footer';

export interface ISignInSplashProps {}

const SignInSplash: React.FC<ISignInSplashProps> = (props) => {
    const styles = useStyles(props);
    const theme = useTheme();

    return (
        <Grid className={cx(styles.classes.BackgroundGradient)} container component='main' sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid className={cx(styles.classes.SplashImage)} container item xs={false} sm={4} md={7}>
                <Grid container className={cx(styles.classes.WelcomeContainer)} justifyContent={'center'} direction={'column'}>
                    <Typography variant={'h2'}>Welcome to Vega!</Typography>
                    <Typography variant={'h6'}>Tools to help you understand and manage your cloud environments.</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sm={8}
                md={5}
                component={Paper}
                elevation={0}
                square
                sx={{
                    zIndex: 100,
                    backgroundColor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
                }}
            >
                <SignIn />
            </Grid>
            <div className={cx(styles.classes.FooterContainer)}>
                <Footer />
            </div>
        </Grid>
    );
};

const useStyles = makeStyles<ISignInSplashProps>()((theme, props) => ({
    FooterContainer: {
        bottom: '0%',
        left: 0,
        position: 'absolute',
        width: '100%',
    },
    WelcomeContainer: {
        marginTop: '6rem',
        marginLeft: '1rem',
    },
    BackgroundGradient: {
        zIndex: -1,
        backgroundImage: `linear-gradient(230deg, ${theme.palette.primary.light}, ${theme.palette.common.white})`,
    },
    SplashImage: {
        backgroundImage: `url(${SignInImage})`,
        backgroundRepeat: 'no-repeat',
    },
}));

export { SignInSplash };
